import { render, staticRenderFns } from "./ReminderSetting.vue?vue&type=template&id=55ce8514&scoped=true&"
import script from "./ReminderSetting.vue?vue&type=script&lang=js&"
export * from "./ReminderSetting.vue?vue&type=script&lang=js&"
import style0 from "./ReminderSetting.vue?vue&type=style&index=0&id=55ce8514&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "55ce8514",
  null
  
)

export default component.exports