<template>
  <div>

    <van-cell-group>
      <van-cell title="提醒时间" :value="remindTime" @click="showTimePopup = true" is-link/>
      <van-cell title="提醒名称" :value="remindName" @click="showNameSheet = true" is-link/>
      <van-cell title="提醒重复" :value="remindRepeat" @click="showRepeatSheet = true" is-link/>
    </van-cell-group>

    <van-popup v-model="showTimePopup" position="bottom" :style="{ height: '30%' }">
      <van-datetime-picker
          v-model="remindTime"
          type="time"
          @confirm="showTimePopup = false"
          @cancel="showTimePopup = false"
          :min-hour="0o0"
          :max-hour="23"/>
    </van-popup>

    <van-action-sheet v-model="showNameSheet" :actions="nameActions" @select="onNameSelect"/>
    <van-action-sheet v-model="showRepeatSheet">
      <van-checkbox-group v-model="remindRepeatStatus">
        <van-cell-group>
          <van-cell
              class="van-cell-customer"
              v-for="(item, index) in repeatChoices"
              clickable
              :key="index"
              :title="`周${item}`"
              @click="toggle(index)">
            <template #right-icon>
              <van-checkbox :name="index" ref="checkboxes"/>
            </template>
          </van-cell>
        </van-cell-group>
      </van-checkbox-group>
      <div class="van-cell-customer" style="text-align: center" @click="confirmRepeat">
        确定
      </div>
    </van-action-sheet>

    <van-button class="button" plain type="info" @click="saveRemind()" square>保存</van-button>
  </div>
</template>

<script>
import {getRemindTitle, getRemindType} from "@/util/remindUtil";
import Vue from 'vue';
import {ActionSheet, Toast} from 'vant';
import {Checkbox, CheckboxGroup} from 'vant';
import {post} from "@/router/httpUtils";
import {Language, OrderSet_SendSuccess} from "@/constants/contants";
import {date2Str} from "@/util/timeUtil";


Vue.use(Checkbox);
Vue.use(CheckboxGroup);
Vue.use(ActionSheet);

export default {
  name: "ReminderSetting",
  data() {
    return {
      remindId: 0,
      remindTime: '',
      remindType: -1,
      remindWeek: '',

      showTimePopup: false,

      showNameSheet: false,
      nameActions: [
        {name: '吃药提醒'},
        {name: '喝水提醒'},
        {name: '运动提醒'},
        {name: '自定义1'},
        {name: '自定义2'},
        {name: '自定义3'},
      ],
      showRepeatSheet: false,
      repeatChoices: ['一', '二', '三', '四', '五', '六', '日',],
      remindRepeatStatus: []
    }
  },
  mounted() {
    const p = this.$route.params
    console.log(p)

    this.remindType = p.EType ? p.EType : -1
    this.remindId = p.Id ? p.Id : 0

    if (p.EWeek) {
      this.remindWeek = p.EWeek
      this.remindRepeatStatus =
          Array.from(this.remindWeek).map((it, i) => {
            if (it === '1') return i
            else return -1
          }).filter(it => it !== -1)
    }
    if (p.ETime) {
      this.remindTime = p.ETime
    } else {
      const currentDate = new Date()
      const hour = String(currentDate.getHours()).length === 1 ? ('0' + currentDate.getHours()) : currentDate.getHours()
      const minute = String(currentDate.getMinutes()).length === 1 ? ('0' + currentDate.getMinutes()) : currentDate.getMinutes()
      this.remindTime = hour + ':' + minute
    }
  },
  computed: {
    remindName() {
      return getRemindTitle(this.remindType)
    },
    remindRepeat() {
      return getRemindType(this.remindWeek)
    },
  },
  watch: {
    remindRepeatStatus: {
      handler(data) {
        const t = [0, 0, 0, 0, 0, 0, 0]
        data.forEach(s => {
          t[s] = 1
        })
        this.remindWeek = t.join('')
      },
      deep: true
    },
  },
  methods: {
    saveRemind() {
      if (!this.remindTime || (this.remindType === -1) || !this.remindWeek) {
        Toast.fail({
          message: '请先设置好提醒参数'
        })
        return
      }

      const voiceTime = new Date()
      voiceTime.setTime(voiceTime.getTime() + voiceTime.getTimezoneOffset() * 60 * 1000)
      voiceTime.setMonth(voiceTime.getMonth() + 1)
      post('/api/Command/SetEventRemind', {
        "DeviceId": localStorage.DeviceId,
        "EContent": "",
        "EIndex": -1,
        "ETime": this.remindTime,
        "EType": this.remindType,
        "EWeek": this.remindWeek,
        "Id": this.remindId,
        "Interval": "4",
        "IsPlay": false,
        "IsVoice": false,
        "Language": Language,
        "Long": "",
        "Model": localStorage.TypeValue,
        "Token": localStorage.Token,
        "UserId": localStorage.UserId,
        "VoiceBase64": "",
        "VoiceTime": date2Str(voiceTime),
        "VoiceUrl": ""
      }).then(res => {
        console.log(res)
        Toast.success({
          message: OrderSet_SendSuccess
        })
        this.$router.back()
      })
      // .catch(err => {
        // Toast.fail({
        //   message: err
        // })
      // })
    },
    onNameSelect(action, index) {
      console.log(action)
      console.log(index)
      this.remindType = index
      this.showNameSheet = false
    },
    toggle(index) {
      this.$refs.checkboxes[index].toggle();
    },
    confirmRepeat() {
      this.showRepeatSheet = false
    }
  }
}
</script>

<style scoped>
.button {
  width: 100%;
  position: absolute;
  bottom: 0;
}

.van-cell-customer {
  padding: 12px 20px;
}
</style>